<template>
    <div class="con-wrap">
        <CarrotTitle title="차량예약">
            - 배차 원칙으로 짐 운반 및 시외 지역 우선으로 배차바랍니다.<br>
            - 서울 시내 나홀로 방문은 가급적 대중교통 이용바랍니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 차량선택</th>
                                <td>
                                    <select class="w-400px" v-model="add.idx_vehicle">
                                        <option value="">선택</option>
                                        <option :value="car.idx" v-for="(car, idx) in comp.carList" :key="idx">{{ (idx+1) }}. {{car.cname}} ({{car.cnum}})</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <label><input type="radio" v-model="add.daytype" @change="comp.changeDaytype" value="today"><span class="ml-5 mr-20"> 하루</span></label>
                                    <div class="po-relative dp-inblock w-150px h-32px">
                                        <div v-if="add.daytype=='period'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.tdate" @change="comp.changeDate" class="mr-40 inline-block"></CarrotDatePicker>
                                    </div>
                                    <label><input type="radio" v-model="add.daytype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-20"> 기간</span></label>
                                    <div class="po-relative dp-inblock w-300px h-32px">
                                        <div v-if="add.daytype=='today'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.sdate" class="inline-block"></CarrotDatePicker>
                                        ~ 
                                        <CarrotDatePicker v-model.sync="add.edate" class="inline-block"></CarrotDatePicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 요일</th>
                                <td>
                                    <div class="po-relative dp-inblock w-400px h-32px">
                                        <div v-if="add.daytype=='today'" class="input-lock"></div>
                                        <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="add.days" :value="d" :disabled="add.daytype=='today'"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시간</th>
                                <td>
                                    <CarrotTime24Select v-model="add.stime"></CarrotTime24Select>
                                     ~ 
                                    <CarrotTime24Select v-model="add.etime"></CarrotTime24Select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 물품유무</th>
                                <td>
                                    <label><input type="radio" v-model="add.hasThing" value="Y"><span class="ml-5 mr-20"> 유</span></label>
                                    <label><input type="radio" v-model="add.hasThing" value="N"><span class="ml-5 mr-20"> 무</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사</th>
                                <td>
                                    <CarrotCompany v-model="add.idx_company" @change="comp.getAddress" class="w-300px"></CarrotCompany>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 주소</th>
                                <td>
                                    <CarrotAddress v-model:zipcode.sync="add.zipcode" v-model:address.sync="add.addr"></CarrotAddress>
                                    <div class="mt-10">
                                        <input type="text" v-model="add.addr_sub" class="w-100per" placeholder="상세주소를 입력하세요.">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>차량사용용도</th>
                                <td>
                                    <input type="text" v-model.trim="add.special_memo" class="w-400px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th>동승자<br>(동승자 기재 필수)</th>
                                <td>
                                    <textarea v-model.trim="add.usage_memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'


export default {
    layout:"ResourceManagement",
    components: {
        CarrotAddress,
        CarrotTime24Select,
        CarrotCompany,
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const add = reactive({
            idx_vehicle  : "",
            daytype      : "today",
            tdate        : "",
            sdate        : "",
            edate        : "",
            stime        : "07:00",
            etime        : "07:00",
            hasThing     : "Y",
            days         : [],
            idx_company  : "",
            zipcode      : "",
            addr         : "",
            addr_sub     : "",
            special_memo : "",
            usage_memo   : "",

            doCancel: () => {
                router.go(-1);
                // router.push({ name:"BusinessManagement-eLearningList" });
            },

            doSubmit : () => {
                let params = {
                    idx_vehicle  : add.idx_vehicle,
                    sdate        : add.daytype=='today'?add.tdate:add.sdate,
                    edate        : add.daytype=='today'?add.tdate:add.edate,
                    sday         : add.days,
                    stime        : add.stime,
                    etime        : add.etime,
                    is_items     : add.hasThing,
                    idx_company  : add.idx_company,
                    zipcode      : add.zipcode,
                    addr         : add.addr,
                    addr_sub     : add.addr_sub,
                    special_memo : add.special_memo,
                    usage_memo   : add.usage_memo,
                };

                if( !params.idx_vehicle ){
                    toast.error("차량을 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( !params.is_items ){
                    toast.error("물품유무를 선택하세요.");
                    return;
                }
                if( !params.idx_company ){
                    toast.error("고객사를 선택하세요.");
                    return;
                }
                if( !params.zipcode || !params.addr || !params.addr_sub ){
                    toast.error("고객사 주소를 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addVehicleReserve", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '차량예약',
                            text : '저장하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-VehicleReservationLogDay'
                            })
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            carList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            getCarList: () => {
                let params = {};
                axios.get('/rest/resourceManagement/carlist', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.carList = res.data.carlist;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            getAddress: () => {
                if( add.idx_company <= 0 ) return;

                let params = {
                    idx : add.idx_company
                };

                axios.get("/rest/customermgr/getCustomerDBInfo", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.zipcode  = res.data.zipcode;
                        add.addr     = res.data.addr;
                        add.addr_sub = res.data.addr_sub;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeDaytype : () => {
                if( add.daytype == 'today' ){
                    add.sdate = '';
                    add.edate = '';
                } else {
                    add.tdate = '';
                }
                add.days = [];
            },

            changeDate: () => {
                add.days = [];
                let td = new Date(add.tdate);
                let day = td.getDay();
                if( day == 0 ) add.days.push(comp.dayList[6])
                else add.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            comp.getCarList();

            let ss = sessionStorage.getItem('VehicleReservationAdd');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today ){
                    add.tdate = ss.today;
                    comp.changeDate();
                }
                if( ss.time ){
                    add.stime = ss.time;
                    add.etime = ss.time;
                }
                if( ss.idx_vehicle ){
                    add.idx_vehicle = ss.idx_vehicle;
                }

                console.log(ss);
            }
        });

        return {comp, add};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>